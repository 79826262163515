import {Component, Input, OnInit} from '@angular/core';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle, NgbNav, NgbNavItem, NgbNavLink,
  NgbOffcanvas
} from "@ng-bootstrap/ng-bootstrap";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet} from "@angular/common";
import {Router, RouterLink, RouterLinkActive} from "@angular/router";
import {UserService} from "../services/backend/user.service";
import {ButtonModule} from "@progress/kendo-angular-buttons";
import {SVGIconModule} from "@progress/kendo-angular-icons";
import * as customSvgIcons from 'src/assets/icons/custom-svg-icons';
import * as allIcons from "@progress/kendo-svg-icons";

@Component({
  standalone: true,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgTemplateOutlet,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    RouterLink,
    NgForOf,
    NgClass,
    RouterLinkActive,
    NgOptimizedImage,
    NgIf,
    ButtonModule,
    SVGIconModule
  ],
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() title?: string;

  customSvgIcons = customSvgIcons;
  allSvgIcons = allIcons;

  collapsed = true;
  links = [
    {
      title: 'Home',
      route: 'home',
      disabled: () => !this.userService.user,
      visible: () => true,
      subRoutes: null
    },
    {
      title: 'Admin',
      route: 'admin',
      disabled: () => !this.userService.isUser,
      visible: () => true,
      subRoutes: null
    },
    {
      title: 'Matching',
      route: '',
      disabled: () => !this.userService.isAdmin,
      visible: () => this.userService.isAdmin,
      subRoutes: [
        {
          title: 'Part Matching',
          route: 'part-matching',
          disabled: () => !this.userService.isAdmin,
          visible: () => this.userService.isAdmin
        },
        {
          title: 'Edit Parts',
          route: 'part-matching/parts',
          disabled: () => !this.userService.isAdmin,
          visible: () => this.userService.isAdmin
        },
        {
          title: 'Module Matching',
          route: 'module-matching',
          disabled: () => !this.userService.isAdmin,
          visible: () => this.userService.isAdmin
        },
      ]
    },
    {
      title: 'Users',
      route: 'user-management',
      disabled: () => !this.userService.isAdmin,
      visible: () => this.userService.isAdmin,
      subRoutes: null
    },
    {
      title: 'Sign In',
      route: 'sign-in',
      disabled: () => false,
      visible: () => !this.userService.user,
      subRoutes: null
    },
  ];

  constructor(
    private offCanvasService: NgbOffcanvas,
    private router: Router,
    public userService: UserService,
  ) {
  }

  ngOnInit(): void {
  }

  open(content: any) {
    this.offCanvasService.open(content, {ariaLabelledBy: 'offcanvas-basic-title', position: "end"});
  }

  close() {
    this.offCanvasService.dismiss();
  }

  async logout() {
    this.userService.logout();
    await this.router.navigate(['/sign-in']);
  }
}
