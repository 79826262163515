import {ImportConfig} from "../import/import-config";
import {BaseTable, column} from "./base/base-table";
import 'reflect-metadata';

export class Festival extends BaseTable {
  @column({field: 'dashboardId', editor: 'numeric'})
  dashboardId?: number | null = null;
  @column({field: 'projectId', editor: 'numeric'})
  projectId?: number | null = null;
  @column({field: 'brandId', editor: 'numeric'})
  brandId?: number | null = null;
  @column({field: 'actualVisitors', editor: 'numeric'})
  actualVisitors?: number | null = null;
  @column({field: 'city', editor: 'text'})
  city?: string | null = null;
  @column({field: 'expectedVisitors', editor: 'numeric'})
  expectedVisitors?: number | null = null;
  @column({field: 'houseNumber', editor: 'text'})
  houseNumber?: string | null = null;
  @column({field: 'image', editor: 'text'})
  image?: string | null = null;
  @column({field: 'name', editor: 'text'})
  name?: string | null = null;
  @column({field: 'nielsen', editor: 'text'})
  nielsen?: string | null = null;
  @column({field: 'notes', editor: 'text'})
  notes?: string | null = null;
  @column({field: 'street', editor: 'text'})
  street?: string | null = null;
  @column({field: 'zip', editor: 'text'})
  zip?: string | null = null;
  eventType?: EventTypeEnums;
  importConfig?: ImportConfig | null = null;
  eventDates?: EventDate[] | null = null;
  budgets?: string | null = null;
  goals?: string | null = null;
  fromDate?: string | null = null;
  tillDate?: string | null = null;

  importStatus?: ImportStatus[] | null = null;

  override getFormControl() {
    const formControl = {
      dashboardId: this.dashboardId,
      projectId: this.projectId,
      brandId: this.brandId,
      actualVisitors: this.actualVisitors,
      city: this.city,
      expectedVisitors: this.expectedVisitors,
      houseNumber: this.houseNumber,
      image: this.image,
      name: this.name,
      nielsen: this.nielsen,
      notes: this.notes,
      street: this.street,
      zip: this.zip,
      importConfig: this.importConfig,
      eventDates: [this.eventDates],
      budgets: this.budgets,
      goals: this.goals,
      eventType: this.eventType,
      fromDate: this.fromDate ? new Date(this.fromDate) : null,
      tillDate: this.tillDate ? new Date(this.tillDate) : null,
    };

    return Object.assign(formControl, super.getFormControl());
  }
}

export enum EventTypeEnums {
  FESTIVAL = "FESTIVAL",
  STATIONARY = "STATIONARY",
  MOBILE = "MOBILE",
  PLOOM_FESTIVAL = "PLOOM_FESTIVAL",
}

export class EventDate {
  id?: string;
  fromDateTime?: string;
  tillDateTime?: string
}


export interface ImportStatus {
  id?: string;
  title?: string;
  imported?: Date;
  rows?: number;
  isExcelImport?: boolean;
  isConfigured?: boolean;
}
